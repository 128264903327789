var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative"
  }, [!_vm.isNpgMasterFetched ? _c('div', {
    staticClass: "spinner-overlay"
  }, [_c('SpinnerVue')], 1) : _vm._e(), _vm.isNpgMasterFetched ? _c('div', [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("NPG")))])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'npg-form'
        });
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-plus"
  }), _vm._v(" " + _vm._s(_vm.$t("New NPG")) + " ")])], 1)]), _c('div', {
    staticClass: "card-body"
  }, [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "fields": _vm.fields,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions,
      "filter": _vm.searchFilter,
      "item-key": "first_name"
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "topbar",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "justify-content-between"
        }, [_c('b-col', {
          staticClass: "d-flex",
          attrs: {
            "cols": "12",
            "lg": "9",
            "xl": "7"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "d-flex",
          attrs: {
            "cols": "12",
            "md": "5"
          }
        }, [_c('label', {
          staticClass: "mr-2 mt-sm-3",
          attrs: {
            "for": "territorialJurisdictions"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Country Filter")))]), _c('euro-select', {
          staticClass: "flex-grow-1",
          attrs: {
            "id": "territorialJurisdictions",
            "label": "",
            "error-messages": _vm.errors,
            "options": _vm.getTerritorialJurisdiction,
            "placeholder": _vm.$t('Select a territorial jurisdiction'),
            "searchable": ""
          },
          model: {
            value: _vm.searchFilter.territorial,
            callback: function callback($$v) {
              _vm.$set(_vm.searchFilter, "territorial", $$v);
            },
            expression: "searchFilter.territorial"
          }
        })], 1), _c('b-col', {
          staticClass: "d-flex",
          attrs: {
            "cols": "12",
            "md": "7"
          }
        }, [_c('label', {
          staticClass: "mr-2 mt-sm-3",
          attrs: {
            "for": "categoriesCollection"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Category Filter")))]), _c('euro-select', {
          staticClass: "flex-grow-1",
          attrs: {
            "id": "categoriesCollection",
            "label": "",
            "error-messages": _vm.errors,
            "options": _vm.getCategories,
            "placeholder": _vm.$t('Select category'),
            "searchable": ""
          },
          model: {
            value: _vm.searchFilter.category,
            callback: function callback($$v) {
              _vm.$set(_vm.searchFilter, "category", $$v);
            },
            expression: "searchFilter.category"
          }
        })], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "lg": "3"
          }
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('i', {
          staticClass: "flaticon-search mt-2 mr-1"
        }), _c('euro-input', {
          staticClass: "w-100",
          attrs: {
            "placeholder": _vm.$t('Search'),
            "debounce": 300
          },
          model: {
            value: _vm.searchFilter.search,
            callback: function callback($$v) {
              _vm.$set(_vm.searchFilter, "search", $$v);
            },
            expression: "searchFilter.search"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }, {
      key: "cell.actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('btn-icon', {
          staticClass: "mr-2",
          attrs: {
            "icon": "flaticon-eye",
            "tooltip": _vm.$t('View Details')
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'npg-view',
                params: {
                  npgId: item.id
                }
              });
            }
          }
        }), _c('btn-icon', {
          staticClass: "mr-2",
          attrs: {
            "icon": "flaticon2-edit",
            "tooltip": _vm.$t('Edit')
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'npg-edit',
                params: {
                  npgId: item.id
                }
              });
            }
          }
        }), _c('btn-icon', {
          attrs: {
            "icon": "flaticon-delete-1",
            "tooltip": _vm.$t('Delete')
          },
          on: {
            "click": function click($event) {
              return _vm.removeItem(item);
            }
          }
        })], 1)];
      }
    }], null, true)
  })], 1)])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }