<template>
  <npg-manage-table></npg-manage-table>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import NpgManageTable from "@/view/components/tables/npg/NpgManageTable.vue";

export default {
  components: {
    NpgManageTable,
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("NPG") }, { title: this.$t("Manage") }]);
  },
};
</script>
