<template>
  <div class="position-relative">
    <div v-if="!isNpgMasterFetched" class="spinner-overlay">
      <SpinnerVue />
    </div>
    <div v-if="isNpgMasterFetched">
      <!-- Data table -->
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder">{{ $t("NPG") }}</h3>
          </div>
          <div class="card-toolbar">
            <b-button variant="success" block @click="$router.push({ name: 'npg-form' })">
              <i class="fas fa-plus"></i>
              {{ $t("New NPG") }}
            </b-button>
          </div>
        </div>
        <div class="card-body">
          <datatable ref="datatableRef" :table-props="tableProps" :total="total" :fields="fields"
            :options.sync="tableOptions" :per-page-options="perPageOptions" :filter="searchFilter" item-key="first_name">
            <template #topbar>
              <b-row class="justify-content-between">
                <b-col cols="12" lg="9" xl="7" class="d-flex">
                  <b-row>
                    <b-col cols="12" md="5" class="d-flex">
                      <label class="mr-2 mt-sm-3" for="territorialJurisdictions">
                        {{ $t("Country Filter") }}</label>
                      <euro-select id="territorialJurisdictions" v-model="searchFilter.territorial" label=""
                        :error-messages="errors" :options="getTerritorialJurisdiction"
                        :placeholder="$t('Select a territorial jurisdiction')" searchable
                        class="flex-grow-1"></euro-select>
                    </b-col>
                    <b-col cols="12" md="7" class="d-flex">
                      <label class="mr-2 mt-sm-3" for="categoriesCollection">
                        {{ $t("Category Filter") }}</label>
                      <euro-select id="categoriesCollection" v-model="searchFilter.category" label=""
                        :error-messages="errors" :options="getCategories" :placeholder="$t('Select category')" searchable
                        class="flex-grow-1"></euro-select>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="3">
                  <div class="d-flex">
                    <i class="flaticon-search mt-2 mr-1"></i>
                    <euro-input v-model="searchFilter.search" :placeholder="$t('Search')" class="w-100"
                      :debounce="300"></euro-input>
                  </div>
                </b-col>
              </b-row>
            </template>


            <template #[`cell.actions`]="{ item }">
              <div class="d-flex">
                <btn-icon icon="flaticon-eye" :tooltip="$t('View Details')" class="mr-2"
                  @click="$router.push({ name: 'npg-view', params: { npgId: item.id } })"></btn-icon>
                <btn-icon icon="flaticon2-edit" :tooltip="$t('Edit')" class="mr-2"
                  @click="$router.push({ name: 'npg-edit', params: { npgId: item.id } })"></btn-icon>
                <btn-icon icon="flaticon-delete-1" :tooltip="$t('Delete')" @click="removeItem(item)"></btn-icon>
              </div>
            </template>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datatable from "@/view/components/tables/Datatable.vue";
import Swal from "sweetalert2";
import SpinnerVue from "../../loaders/Spinner.vue";
import { mapGetters, mapState } from "vuex";
import NpgService from "@/core/services/npg/npg.service";
import DateService from "@/core/services/date.service";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    Datatable,
    SpinnerVue,
  },

  data() {
    return {
      total: 0,
      errors: [],
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      searchFilter: {
        search: "",
        territorial: "",
        category: "",
      },
      sortBy: "name",

      fields: [
        { key: "view_more", label: "", class: "align-middle w-50px" },
        { key: "name", sortable: true, label: this.$i18n.t("Name"), class: "align-middle" },
        {
          key: "territorial_jurisdiction",
          label: this.$i18n.t("Territorial jurisdiction"),
          sortable: true,
          class: "align-middle",
        },
        {
          key: "administrative_structure",
          label: this.$i18n.t("Administrative structure"),
          sortable: true,
          class: "align-middle",
        },
        { key: "categories", sortable: true, label: this.$i18n.t("Categories"), class: "align-middle" },
        { key: "legal_type", label: this.$i18n.t("Legal type"), sortable: true, class: "align-middle" },
        { key: "created", label: this.$i18n.t("created"), sortable: true, class: "align-middle" },
        { key: "actions", label: this.$i18n.t("Actions"), class: "align-end col-actions" },
      ],
    };
  },
  computed: {
    ...mapState("npg", ["administrativeStructure", "isNpgMasterFetched"]),
    ...mapGetters("npg", ["getTerritorialJurisdiction", "getCategories"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
      };
    },
  },
  methods: {
    processNpgListData(npgDatas) {
      return npgDatas.flatMap(npgData => [
        {
          id: npgData.id,
          name: npgData.name,
          territorial_jurisdiction: this.getTerritorialJurisdiction.filter(el => {
            return el.value == npgData.territorial_jurisdiction;
          })[0].text,
          administrative_structure: this.administrativeStructure.filter(el => {
            return el.id == npgData.administrative_structure;
          })[0].name,
          categories: this.filterOutCategories(npgData.categories).join(", "),
          legal_type: this.filterLegalTypeToDisplay(
            this.administrativeStructure.filter(el => {
              return el.id == npgData.administrative_structure;
            })[0],
            npgData.legal_type,
          ),
          created: npgData.created ? DateService.format(npgData.created, "DD MMMM, YYYY - H:mm") : "",
          viewMore: {
            tags: npgData.tags.join(", "),
            validFrom: npgData.valid_from ? DateService.format(npgData.valid_from) : "",
            validTo: npgData.valid_to ? DateService.format(npgData.valid_to) : "",
            lastEdit: npgData.updated ? DateService.format(npgData.updated, "DD MMMM, YYYY - H:mm") : "",
          },
        },
      ]);
    },

    filterLegalTypeToDisplay(administrativeStructureData, legalTypeId) {
      return administrativeStructureData.legal_type.filter(el => {
        return el.id == legalTypeId;
      })[0].name;
    },

    filterOutCategories(categoriesIds) {
      return this.getCategories
        .filter(el => {
          return categoriesIds.includes(el.value);
        })
        .map(el => el.text);
    },

    itemProvider(ctx, callback) {

      NpgService.getAll({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "created",
        sortDesc: ctx.sortDesc,
        search: ctx.filter.search ? ctx.filter.search : "",
        territorial_jurisdiction: ctx.filter.territorial ? ctx.filter.territorial : "",
        categories: ctx.filter.category ? ctx.filter.category : [],
      })
        .then(res => {
          this.total = res.count;
          callback(this.processNpgListData(res.results));
        })
        .catch(() => {
          callback([]);
          this.errorCatch();
        });
      return null;
    },
    edit(item) {
      this.$router.push({
        name: "npg-edit",
        params: { ID: item.id },
      });
    },
    removeItem(item) {
      Swal.fire({
        title: this.$i18n.t("Delete"),
        text: this.$i18n.t("Delete the npg") + ` ${item.name}?`,
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        confirmButtonText: "Yes, delete",
        showCancelButton: true,
        cancelButtonText: "No, cancel",
        cancelButtonClass: "btn",
      }).then(async res => {
        if (res.isConfirmed) {
          try {
            await NpgService.delete(item.id);
            this.$bvToast.toast("Npg data deleted successfully", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.refresh();
          } catch (err) {
            console.log(err);
            this.errorCatch(`Failed to delete ${item.name}. Please try again.`);
          }
        }
      });
    },
    refresh() {
      this.$refs.datatableRef.refresh();
    },
    errorCatch(errorMessage) {
      this.$bvToast.toast(errorMessage ? errorMessage : "Failed to load. Please try later.", {
        title: "Error",
        variant: "danger",
        solid: true,
        noAutoHide: true,
      });
    },
  },
};
</script>

<style>
.custom-file-upload .custom-file-input~.custom-file-label {
  border-color: #cbcbcb;
  border-width: 2px;
  border-style: dashed;
  background-color: #f1f1f1;
  height: 4rem;
  padding: 1rem;
}

.custom-file-upload .custom-file-input.is-valid~.custom-file-label {
  border-color: #1bc5bd;
  background-color: #fff;
}

.custom-file-upload .custom-file-input~.custom-file-label[data-browse]::after {
  display: none;
}

.w-50px {
  width: 50px;
}

.spinner-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-block-start: 10%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.65);
}

.position-relative {
  position: relative;
}
</style>
